import React, { useEffect } from 'react'
import { setupAnalytics } from '@helpers/google-tag-manager'
import OrderStatus from './order-status'

const OrderStatusWrapper = () => {
  useEffect(() => {
    setupAnalytics({ pageData: { type: 'order', title: 'Order Status', path: '/order/status' } })
  }, [])

  return <OrderStatus />
}

export default OrderStatusWrapper
